// api/user.ts
import { get, post } from "@/utils/request";
// 用户注册
export function register(data) {
    return post("/app/user/register", data);
}
// 用户登录
export function login(data) {
    return post("/app/user/login", data);
}
// 重置密码
export function resetPassword(data) {
    return post("/app/user/resetPassword", data);
}
// 用户个人信息
export function getUserInfo() {
    return get("/app/user/getUserInfo");
}
// 获取用户余额
export function getUserBalance() {
    return get("/app/user/getUserBalance");
}
// 修改绑定 Telegram 接口
export function resetTg(data) {
    return post("/app/user/resettg", data);
}
// 分页查询 用户帐变明细分页查询
export function pagesettles(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/pagesettles?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 分页查询 账户订单分页查询
export function pageorders(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/pageorders?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}

import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { login, getUserInfo } from "@/api/userInfo"; // 导入登录接口

export default defineComponent({
  name: "login",

  setup() {
    // 用 form 对象统一管理表单数据
    const form = ref({
      phone: "",
      password: "",
      selectedCode: "+86" // 国家区号，默认 +86

    }); // 错误提示对象

    const errors = ref({
      phone: "",
      password: ""
    });
    const showDropdown = ref(false); // 控制下拉框显示状态

    const countryCodes = ref([{
      text: "+86",
      value: "+86"
    }, {
      text: "+1",
      value: "+1"
    }, {
      text: "+44",
      value: "+44"
    }]);
    const router = useRouter();

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const selectCode = code => {
      form.value.selectedCode = code;
      showDropdown.value = false; // 选择后关闭下拉框
    };

    const toDetail = path => {
      router.push(path);
    }; // 校验函数


    const validateForm = () => {
      errors.value.phone = form.value.phone ? "" : "Phone number is required";
      errors.value.password = form.value.password ? form.value.password.length >= 6 ? "" : "Password must be at least 6 characters" : "Password is required"; // 返回校验结果

      return !errors.value.phone && !errors.value.password;
    };

    const onSubmit = async () => {
      if (validateForm()) {
        // 将国家区号和手机号码拼接在一起传递给后端
        const fullPhone = form.value.selectedCode.replace("+", "") + form.value.phone;

        try {
          // 调用登录接口
          const response = await login({
            loginName: fullPhone,
            password: form.value.password
          });

          if (response.code === 1) {
            const token = response.data; // 获取返回的 token
            // 将 token 保存到 localStorage 中

            localStorage.setItem("token", token); // 获取用户信息

            try {
              const userInfoResponse = await getUserInfo(); // 调用获取用户信息的接口

              if (userInfoResponse.code === 1) {
                const userInfo = userInfoResponse.data; // 获取用户信息

                localStorage.setItem("userInfo", JSON.stringify(userInfo)); // 将用户信息存储到 localStorage 中

                _Toast.success("Login successful!"); // 登录成功提示
                // 延迟跳转到首页


                setTimeout(() => {
                  router.push("/home"); // 登录成功后跳转到首页
                }, 2000);
              } else {
                _Toast.fail(userInfoResponse.msg || "Failed to fetch user information");
              }
            } catch (error) {
              console.error("获取用户信息失败", error);

              _Toast.fail("Failed to fetch user information, please try again");
            }
          } else {
            _Toast.fail(response.msg || "Login failed"); // 登录失败提示

          }
        } catch (error) {
          console.error("登录失败", error);

          _Toast.fail("Network error, please try again"); // 网络错误提示

        }
      } else {
        console.log("校验失败，请检查表单");
      }
    };

    return {
      form,
      errors,
      showDropdown,
      countryCodes,
      toggleDropdown,
      selectCode,
      onSubmit,
      toDetail
    };
  }

});